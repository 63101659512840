import React from 'react';
import Header from '../components/Header';
import MobileHeader from '../components/mobileHeader'
import { HStack, Box, Image, Text, Button } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import "../index.css";
import { Link } from "react-router-dom";
import List from './depart/List'

function Depart(){
	return(
		<>
			<Box className="d_mb_none">
			<Header />
		</Box>

		<Box className="d_desk_none">
			<MobileHeader/>
		</Box>

			<List />

			
			
		</>
	)
}

export default Depart;