import React,{useEffect,useState} from 'react';
import Pagination from "react-js-pagination";

import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
} from '@chakra-ui/react'
import {Link} from 'react-router-dom';
import { fetchWrapper } from '../../helpers';
import moment from 'moment';
import * as Yup from 'yup';

import {InputText,Form,SelectFieldSearch} from '../../components/form'





function List(){
  const [activePage, setActivePage] = useState(1);
  const [listCount, setListCount] = useState(0); 
  const [loading,setLoading] = useState(false) 
  const [search, setsearch] = useState("");
  const [list,setList] = useState([])
  const [userlist,setuserList] = useState([])

  const [formData, setFormData] = useState({
   
    user_id:''
    
  });
  const FormSchema = Yup.object().shape({

     // user_id: Yup.string().required('Required'),

  });


  useEffect(() => {
        loadList(formData.user_id,activePage)  
        loadListfilter()      
    }, [activePage,formData.user_id]);

    const decodeToken = (token) => {
      const decodedString = atob(token.split('.')[1]);
      const decodedObject = JSON.parse(decodedString);
      // console.log(decodedObject)
      return decodedObject;
    };

  const loadList = async (search="",town_id="") => {
      setLoading(true)
      const token = localStorage.getItem('user');

    // If the token exists, decode it to get user data
    if (token) {
      const decodedToken = decodeToken(token);
      const isSuperAdmin = decodedToken.Userdata.superadmin === 1;
      const userTownId = decodedToken.Userdata.town_id;
      console.log(userTownId)

      // If user is superadmin, use the provided town_id; otherwise, use user's town_id
      if (isSuperAdmin) {
        town_id = '';
      } else {
        town_id = userTownId;
      }
    }
      const url = `${process.env.REACT_APP_API_URL}/api/contact-list?page=${activePage}&user_id=${search}&town_id=${town_id}`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows);
      setListCount(data.count);
      setLoading(false)
      
  }

  const loadListfilter = async () => {
      setLoading(true)
      const token = localStorage.getItem('user');

      // If the token exists, decode it to get user data
      let isSuperAdmin;
      let userTownId
      if (token) {
        const decodedToken = decodeToken(token);
         isSuperAdmin = decodedToken.Userdata.superadmin === 1;
         userTownId = decodedToken.Userdata.town_id;
      }
      // console.log(userTownId)
      const url = isSuperAdmin
        ? `${process.env.REACT_APP_API_URL}/api/contact-list-user`
        : `${process.env.REACT_APP_API_URL}/api/contact-list-user?town_id=${userTownId}`;
      // const url = `${process.env.REACT_APP_API_URL}/api/contact-list-user`;
      const data  = await fetchWrapper.get(url)
      setuserList(data.rows);
      setLoading(false)
      
  }

  const onChangePaginate = (pageNumber) => {
    setActivePage(pageNumber);
    // to set offset
    console.log(pageNumber);
  };

  const ChangeSelectList =(e)=>{
    // console.log(e.target)
    setsearch(e.target.value.toLowerCase())
  }

  const handleClickold = ()=>{
    loadList(search)
   
  }


   const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
    setFormData(values)
    setActivePage(1)
    // let  data = values

    
      
    //      loadList(data.user_id)
  }

  const isSuperAdmin = () => {
    const token = localStorage.getItem('user');
    if (token) {
      const decodedToken = decodeToken(token);
      return decodedToken.Userdata.superadmin === 1;
    }
    return false;
  };

  return(
    <>
      <div className="body_section">
       {loading &&
          <HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </HStack>
        }
        
        <HStack justifyContent="space-between" mb="25px">
          <Box>
            <Text fontSize="24px" fontWeight="600" color="#000000" my="0px" fontFamily="'Open Sans', sans-serif">Voter Contacts</Text>
          </Box>

              
        </HStack>

        

        <Box mb="10px">
        <Form
                  enableReinitialize
                  validationSchema={FormSchema}
                  initialValues={formData}
                  onSubmit ={onSubmit}           
                  
       >
        <HStack>
          <Box>
          
        <Box mt="15px" mb={3} className ="voter-search">
        <SelectFieldSearch 
             name="user_id"
             options={userlist}
           />
        </Box>

          </Box>
          <Box>
            <Button py="10px" px="25px" color="#fff" bg="#2D7A06" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif" 
                    
                    
                    type='submit'
                  >
                   Apply Filter
                </Button>
          </Box>

          </HStack>
          </Form>
        </Box>
                          

        <div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Name</Th>
                  <Th>CNIC #</Th>
                  <Th>Contact #</Th>
                  <Th>Added by</Th>
                  <Th>Added On</Th>
                  <Th></Th>


                  

                  
                </Tr>
              </Thead>

              <Tbody fontSize="14px">

              {list.map((rs,index)=>{
                    return(
                           <Tr key={index}>
                            <Td>{rs.name}</Td>
                            <Td>{rs.cnic}</Td>
                            <Td>{rs.mobile}</Td>
                             <Td>{rs.User?.name}</Td>
                            <Td>{moment(rs.createdAt).format('DD MMMM, YYYY')}</Td>
                          <Td></Td>
                      
                            </Tr>
                          )
                  })}
             
                              
              </Tbody>
             
              
            </Table>
            <Pagination
               activePage={activePage}
               itemsCountPerPage={100}
               totalItemsCount={listCount}
               pageRangeDisplayed={5}
               onChange={onChangePaginate}
               innerClass="pagination"
               itemClass="page-item"
               activeLinkClass="page-link active"
               linkClass="page-link"
               prevPageText="Previous"
               nextPageText="Next"
              />
          </TableContainer>
        </div>
      </div>

      
    </>
    )
}

export default List;