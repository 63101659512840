import Select from 'react-select';

const MultiSelect = ({
    field,
    form,
    options,
    isMulti = false,
    title,
    placeholder = 'Select'
}) => {
    function onChange(option) {
        form.setFieldValue(
            field.name,
            option ? option.map((item) => item.value) : [],
        );
    }

    const getValue = () => {
        if (options) {
            if (isMulti) {
                return options.filter((option) => (field.value || []).indexOf(option.value) >= 0);
            } else {
                return options.find((option) => option.value === field.value) || null;
            }
        } else {
            return isMulti ? [] : null;
        }
    };

    if (!isMulti) {
        return (
            <div className="field_section">
                <label>{title}</label>
                <Select
                    options={options}
                    name={field.name}
                    value={options ? options.find((option) => option.value === field.value) : ''}
                    onChange={(option) => form.setFieldValue(field.name, option.value)}
                    onBlur={field.onBlur}
                    placeholder={placeholder}
                />
            </div>
        );
    } else {
        return (
            <div className="field_section">
                <label>{title}</label>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name={field.name}
                    value={getValue()}
                    onChange={onChange}
                    options={options}
                    isMulti={true}
                    placeholder={placeholder}
                />
            </div>
        );
    }
};

export default MultiSelect;
