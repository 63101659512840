import React from 'react'

import * as Highcharts from 'highcharts';

import HighchartsReact from 'highcharts-react-official'


const Town_Chart = ({datalist}) => {

    const chartvalue = datalist?.TownVoterCount.map(item => {
                return {
                    name: item.name,
                    y: item.voter,
                    color: '#00B750'
                    
                }
     })

	const options ={
    chart: {
        type: 'column'
    },
    title: {
        align: 'left',
        text: 'Town Wise Voters'
    },
    subtitle: {
        align: 'left',
        
    },
    accessibility: {
        announceNewData: {
            enabled: true
        }
    },
    xAxis: {
        type: 'category'
    },
    yAxis: {
        title: {
            text: 'Total'
        }

    },
    legend: {
        enabled: false
    },
    plotOptions: {
        series: {
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                format: '{point.y:.1f}'
            }
        }
    },

    tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
    },

    series: [
        {
            name: 'Town',
            colorByPoint: true,
            data: chartvalue
        }
    ],
};

	return(
		<>
		<HighchartsReact
                highcharts={Highcharts}
                options={options}
       	/>

		</>
		)
}

export default Town_Chart;