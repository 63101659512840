import React,{useState,useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import userimg from '../images/user_img.png';
import Headerlogo from '../images/mqm.png';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { HStack,
    Box,
    Image,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button } from '@chakra-ui/react';

function MobileHeader() {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
      // Check if the token is present in local storage
      const token = localStorage.getItem('user');


      if (token) {
        const decodedToken = decodeToken(token);
        const Userdata = decodedToken.Userdata;
        // console.log(Userdata)

        // Set the email state
       
        setIsSuperAdmin(Userdata.superadmin === 1)
      }
    }, []);
  const decodeToken = (token) => {
      const decodedString = atob(token.split('.')[1]);
      const decodedObject = JSON.parse(decodedString);
      // console.log(decodedObject)
      return decodedObject;
    };

  return (
    <Navbar expand="lg" className="bg-body-tertiary Header-mobile"  >
      <Container>
        <Navbar.Brand href="/dashboard">
        <Image src={Headerlogo}  style={{borderRadius:"50%"}} w="30px" h="30px"  />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className="MobLink" href="/dashboard">Dashboard</Nav.Link>
            <Nav.Link className="MobLink" href="/voter">Voter contact</Nav.Link>
            <Nav.Link className="MobLink" href="/User">User</Nav.Link>

            {isSuperAdmin && (
              <Nav.Link className="MobLink" href="/district">District and Towns</Nav.Link>
            )} 

            {isSuperAdmin && (
              <Nav.Link className="MobLink" href="/depart">Depart</Nav.Link>
            )}  
            <Nav.Link className="MobLink" href="/town-summary">Town-Summary</Nav.Link>
            {isSuperAdmin && (
              <Nav.Link className="MobLink" href="/web-user">Setting</Nav.Link>
            )}

            
             <NavDropdown title={<span style={{ color: 'green' }}>Reports</span>} className="MobLink" style={{ color: 'green' }} >
              <NavDropdown.Item href="/cnic">Cnic</NavDropdown.Item>
              <NavDropdown.Item href="/contact">Contact</NavDropdown.Item>
            </NavDropdown>
            

            <Nav.Link className="MobLink" href="/">Logout</Nav.Link> 
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MobileHeader;