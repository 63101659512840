import React ,{useState} from 'react';
import './App.css';
import Login from './pages/auth/Login';
import Dashboard from './pages/Dashboard';
import Voter from './pages/Voter-Contact';
import User from './pages/User';
import District from './pages/District';
import Depart from './pages/Depart';
import Town_Summary from './pages/Town-Summary';
import Cnin_Details from './pages/Report/Cnic-details';
import Contact_Details from './pages/Report/Contact-details';
import WebUser from './pages/Web-User.js'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react';
import { PrivateRoute } from './components/PrivateRoute';


function App() {
  const [activePage, setActivePage] = useState("");
  return (
   <>
    <ChakraProvider>
      <BrowserRouter>
        <Routes>            
          <Route path="/" element={<Login />} />
          <Route path="/dashboard"
          setActivePage={setActivePage}
           element=
          <PrivateRoute>
          {<Dashboard/>}
          </PrivateRoute>
           />


          <Route path="/voter"
          setActivePage={setActivePage}
          element=
          <PrivateRoute>
          {<Voter/>}
          </PrivateRoute>
          />

          <Route path="/user"
          setActivePage={setActivePage}
          element=
          <PrivateRoute>
          {<User/>}
          </PrivateRoute>
           />


          <Route path="/district"
          setActivePage={setActivePage}
          element=
          <PrivateRoute>
          {<District/>}
          </PrivateRoute>
           />

          <Route path="/depart"
            setActivePage={setActivePage}
            element=
            <PrivateRoute>
            {<Depart/>}
            </PrivateRoute>
             />

          <Route path="/town-summary"
          setActivePage={setActivePage}
          element=
          <PrivateRoute>
          {<Town_Summary/>}
          </PrivateRoute>
           />

           <Route path="/web-user"
          setActivePage={setActivePage}
          element=
          <PrivateRoute>
          {<WebUser/>}
          </PrivateRoute>
           />

           <Route path="/cnic"
          setActivePage={setActivePage}
          element=
          <PrivateRoute>
          {<Cnin_Details/>}
          </PrivateRoute>
           />

           <Route path="/contact"
          setActivePage={setActivePage}
          element=
          <PrivateRoute>
          {<Contact_Details/>}
          </PrivateRoute>
           />
                 
          </Routes>
      </BrowserRouter>
    </ChakraProvider>
   </>
  );
}

export default App;
