import React,{useEffect,useState} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner
} from '@chakra-ui/react'
import {Link} from 'react-router-dom';
import CustomModalLink from '../../components/CustomModalLink'
import CustomModal from '../../components/CustomModal'
import Createtown from './Createtown'
import Createdist from './Createdistrict'
import { fetchWrapper } from '../../helpers';
import TownList from './TownList'
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import { toast } from "react-toastify";
import DistrictEdit from './DistrictEdit'

function List(){
  const [loading,setLoading] = useState(false)
 
  const [listdist,setListdistrict] = useState([]);
    const { isOpen, onOpen, onClose} = useDisclosure();
  const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
  const [isOpenEdit,setIsOpenEdit] = useState(false)   
  const [modalEditView,setModalEditView] = useState(false)
  const [show,setShow] = useState(false)


  useEffect(() => {
        loadDistrictList();
           
        

    }, [show]);

 
  const loadDistrictList= async () => {
     setLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/api/district-list`;
      const data  = await fetchWrapper.get(url)
      setListdistrict(data.rows);
      // console.log(data.rows)
       setLoading(false)
      
  }





  const meCloseEdit = () => {
      
       setIsOpenEdit(false);
       setShow(!show)
       
  }
  const meClose = () => {
    onClose();
  };
  
 

  const meOpenModel = (view) => {
    setIsOpenEdit(true);
    if(view == 'add_town'){
      
        setModalEditView(<Createtown meCloseEdit={meCloseEdit} loadList={loadDistrictList} />)    
    }

    if(view == 'add_district'){
        setModalEditView(<Createdist  meCloseEdit={meCloseEdit} loadList={loadDistrictList}  />)   
    }    
  }

  const meEdit =(district)=>{
    setIsOpenEdit(true);
    setModalEditView(<DistrictEdit rs={district} meCloseEdit={meCloseEdit}  loadList={loadDistrictList} />)
  }

  const meDelete = async (district) => {
      const aa = window.confirm("Are you sure delete this record");
      if(aa){
        setLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/api/district-delete/${district.id}`;
        await fetchWrapper.delete(url)
         loadDistrictList()
         setLoading(false)
         toast(`Record has been deleted `, {
          type: "info"
        });
      }
  }

 

  return(
    <>
   <CustomModalLink
           showModalButtonText="Edit"
           modalHeader=""
           isOpenEdit={isOpenEdit}
           onCloseEdit={meCloseEdit}
           onOpenEdit={onOpenEdit}       
           modalBody={modalEditView}
         />

      <div className="body_section district_page">
      {loading &&
          <HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </HStack>
        }


        
        {/*Desktop Code*/}
        <HStack justifyContent="space-between" mb="25px" className="d_mb_none">
          <Box>
            <Text fontSize="24px" fontWeight="600" color="#000000" my="0px" fontFamily="'Open Sans', sans-serif">Districts & Towns</Text>
          </Box>
          

          <HStack>
            <Box  color="#fff" fontSize="14px" fontWeight="700">
              <div onClick={() => meOpenModel('add_town')} id="button1"  >Add Town</div>
            </Box>
             <Box  >
              <div onClick={() => meOpenModel('add_district')} id="button2">Add District</div>
            </Box>
          </HStack>             
        </HStack>


          {/*Mobile Code*/}
          <Box className="d_desk_none">
            <Text fontSize="24px" fontWeight="600" color="#000000" my="0px" fontFamily="'Open Sans', sans-serif">Districts & Towns</Text>

            <HStack>
              <Box  color="#fff" fontSize="14px" fontWeight="700">
                <div onClick={() => meOpenModel('add_town')} id="button1"  >Add Town</div>
              </Box>
               <Box  >
                <div onClick={() => meOpenModel('add_district')} id="button2">Add District</div>
              </Box>
            </HStack> 
          </Box>
          

          
            
        
        {listdist.map(district => (
                <div key={district.id}>
                <div className="table_section">
                  <TableContainer width="100%" mb="20px">
                    <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
                        <Thead>
                        <Tr>
                        <Th>{district.name}</Th>
                        <Th>Town Incharge</Th>
                         <Th>
                        <HStack mb="5px" mr="65px" justifyContent="right" >
                          <a 
                           onClick={() => meEdit(district)}>
                            <BsPencil />
                          </a>  
                          <a onClick={() => meDelete(district)}>
                            
                            <BsFillTrashFill />
                          </a>
                          </HStack>
                              </Th>
                        </Tr>
                          
                        </Thead>
                          <Tbody>
                          

                                <TownList show={show} district_id={district.id} loadlist={loadDistrictList} />
                             
                          </Tbody>

                  </Table>
                </TableContainer>
              </div>
            </div>
              ))}
       

      </div>   
    </>
    )
}

export default List;