import React,{useEffect,useState} from 'react';
import { Button,useDisclosure ,Spinner,HStack} from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,SelectField} from '../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../helpers';




function EditUser({rs,loadList,meCloseEdit}){
	
	const [townList,SettownList] = useState();
	const [loading,setLoading] = useState(false)

	const [formData, setFormData] = useState({
    name: rs.name,
    email: rs.email,
    town_id: rs.town_id,
    id:rs.id
    
  });

  	const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   email: Yup.string().required('Required'),
	   town_id: Yup.string().required('Required'),
	   

	});
	useEffect(()=>{
		
		loadtownList();

		
	},[])

  	const loadtownList = async () => {
		const url = `${process.env.REACT_APP_API_URL}/api/user-list-by-town`;
      const data  = await fetchWrapper.get(url)
      	SettownList(data)  
  	}
  	

  	


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
 		setLoading(true)
		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/user-update/${rs.id}`;
  		 await fetchWrapper.put(url,data)
  		 loadList()
  		 meCloseEdit();
  		  setLoading(false)
  		 toast(`Record has been update `, {
	        type: "info"
	      });
	}

	
	const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]

	

	return (

			<>
			{loading &&
	          <HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
	            <Spinner
	              thickness='4px'
	              speed='0.65s'
	              emptyColor='gray.200'
	              color='blue.500'
	              size='xl'
	            />
	          </HStack>
	        }
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="name" title=" Name"  />
			 	<InputText name="email" title=" Email ID"  />
			 	
			 	<SelectField list={townList} name="town_id" title="Town Name"  />
			 	{/*<SelectField list={DistrictList} name="district_id" title="District Name"  />*/}
			 	

		         <Button py="10px" px="25px" color="#fff" bg="#2D7A06" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>


		          		<Button py="10px" px="25px" color="#001F3F" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
		            mb={4}
		            colorScheme='whiteAlpha'
		            onClick={meCloseEdit}
		            type='button'
          		>
           		 Close
          		</Button>

			</Form>

			</>

	)
}

export default EditUser;