import React,{useEffect,useState} from 'react';
import Pagination from "react-js-pagination";
import VeiwCnic from './VeiwCnic'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Switch
} from '@chakra-ui/react'
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import {Link} from 'react-router-dom';
import { fetchWrapper } from '../../helpers';
import moment from 'moment';
import * as Yup from 'yup';
import CustomModalLink from '../../components/CustomModalLink'
import CustomModal from '../../components/CustomModal'
import {InputText,Form,SelectFieldSearch} from '../../components/form'
import Header from '../../components/Header'
import MobileHeader from '../../components/mobileHeader'
import { toast } from "react-toastify";




function Cnic_details(){
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [activePage, setActivePage] = useState(1);
	const [loading,setLoading] = useState(false) 
	const [list,setList] = useState([])
   const [listCount, setListCount] = useState(0); 
   const [CnicCount, setCnicCount] = useState(0); 


  useEffect(() => {
    const token = localStorage.getItem('user');

      // If the token exists, decode it to get user data
      let isSuperAdmin;
      let userTownId
      if (token) {
        const decodedToken = decodeToken(token);
         isSuperAdmin = decodedToken.Userdata.superadmin === 1;
         userTownId = decodedToken.Userdata.town_id;
      }
        loadList(isSuperAdmin,userTownId)  
    
    }, [activePage]);
    const decodeToken = (token) => {
      const decodedString = atob(token.split('.')[1]);
      const decodedObject = JSON.parse(decodedString);
      // console.log(decodedObject)
      return decodedObject;
    };


  	const loadList = async (superadmin,town_id) => {
      setLoading(true)
       const url = superadmin
        ? `${process.env.REACT_APP_API_URL}/api/report?page=${activePage}`
        : `${process.env.REACT_APP_API_URL}/api/report?page=${activePage}&town_id=${town_id}`;
      // const url = `${process.env.REACT_APP_API_URL}/api/report?page=${activePage}`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows);
      setListCount(data.count[0].user_count)
      const cnicCount = data.countErr[0]?.total_cnic_count || 0;
      setCnicCount(cnicCount)
      setLoading(false)
  	}

	const onChangePaginate = (pageNumber) => {
	    setActivePage(pageNumber);
	    // to set offset
	    console.log(pageNumber);
	};

    const meClose = () => {
      
       onClose();
       
  }

  const meCloseEdit = () => {
      
       setIsOpenEdit(false);
       
  }

  const meRecord = (rs) => {
    setIsOpenEdit(true);
    setModalEditView(<VeiwCnic rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)   
  }


  return(
    <>
    <CustomModalLink
          showModalButtonText="Edit"
          modalHeader="Cnic Veiw"
          isOpenEdit={isOpenEdit}
          onCloseEdit={meCloseEdit}
          onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />


        {/* Hearder Section */}
    <Box className="d_mb_none">
      <Header />
    </Box>

    <Box className="d_desk_none">
      <MobileHeader/>
    </Box>


      <div className="body_section">
       {loading &&
          <HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </HStack>
        }
        
        <HStack justifyContent="space-between" mb="25px">
          <Box>
            <Text fontSize="24px" fontWeight="600" color="#000000" my="0px" fontFamily="'Open Sans', sans-serif">Cnic Details</Text>
          </Box>
        </HStack>

        <HStack mb="30px" >
          <Box borderWidth="2px" borderColor="#ccc" w="250px" me="10px">
            <Box  px="10px">
              <Text fontSize="36px" fontWeight="600" color="#000">{listCount}</Text>
            </Box>

            <Box py="0px" px="10px">
              <Text color="#000000" fontSize="14px" fontWeight="700">Total Users</Text>
            </Box>
          </Box>
          <Box borderWidth="2px" borderColor="#ccc" w="250px" me="15px">
            <Box px="10px">
              <Text fontSize="36px" fontWeight="600" color="#000">{CnicCount}</Text>
            </Box>

            <Box py="0px" px="10px">
              <Text color="#000000" fontSize="14px" fontWeight="700">Total Cnic Error</Text>
            </Box>
          </Box>
        </HStack>

                           

        <div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>UserName</Th>
                  <Th>Email</Th>
                  <Th>Contact</Th>
                  <Th>Town</Th>
                  <Th>Count</Th>
                  <Th></Th>
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
              {list.length > 0 ? (
                list.map((rs, index) => (
                  <Tr key={index}>
                    <Td>{rs.name}</Td>
                    <Td>{rs.email}</Td>
                    <Td>{rs.contact_no}</Td>
                    <Td>{rs.town}</Td>
                    <Td onClick={()=> meRecord(rs)} className="linkss">{rs.cnic_count}</Td>
                    <Td></Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="6">No data found</Td>
                </Tr>
              )}
              
              </Tbody>
              
            </Table>
            <Pagination
               activePage={activePage}
               itemsCountPerPage={100}
               totalItemsCount={listCount}
               pageRangeDisplayed={5}
               onChange={onChangePaginate}
               innerClass="pagination"
               itemClass="page-item"
               activeLinkClass="page-link active"
               linkClass="page-link"
               prevPageText="Previous"
               nextPageText="Next"
              />
          </TableContainer>
        </div>
      </div>

      
    </>
    )
}

export default Cnic_details;