import React,{useState} from 'react';
import { HStack, Box, Image ,Text} from '@chakra-ui/react';
import * as Yup from 'yup';
import { Link ,useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import { fetchWrapper } from '../../helpers';

import {
  
  Button
} from '@chakra-ui/react';
import logo from '../../images/mqm.png';
import {InputText,Form} from '../../components/form'

function Login() {
  const navigate  = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    
    
  });
  const FormSchema = Yup.object().shape({
       email: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
       
  });
  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
    try{
      let  data = values

     const url = `${process.env.REACT_APP_API_URL}/api/web-user-login`;
     const loginData = await fetchWrapper.post(url,data)

     const token = loginData.accesstoken
     localStorage.setItem("user",token);
     navigate("/dashboard"); 
    }
    catch{
      toast(`Password is invalid `, {
          type: "info"
        });
    }
       
        // console.log(loginData.accesstoken)
  }



  return (
    <HStack  h="100vh" justifyContent="center" flexDirection="column">
      <Box mb="20px">
        <Image src={logo} alt='Mqm' />
      </Box>
      <Text align="center">Please login into your account</Text>
      <Box width="351px" bg="#fff" py="30" px="25">

      <Form 
                  enableReinitialize
                  validationSchema={FormSchema}
                  initialValues={formData}
                  onSubmit ={onSubmit}
                 
      >
          <Box mb="20px">
           <InputText name="email" title="User Name"   />
          </Box>
          <Box mb="15px">
           <InputText inputProps={{type:'password'}}  name="password" title="Password"  />
          </Box>


           <Button type="submit" bg="#2D7A06" borderColor="#504F56" borderRadius="5px" fontSize="14px" fontWeight="500" color="#fff" width="100%" p="15" borderWidth="1px" borderStyle="solid" mt="10px">Login</Button>
       </Form>
        

        
      </Box>
    </HStack>
  );
}

export default Login;
