import React,{useEffect,useState} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Switch
} from '@chakra-ui/react'
import Pagination from "react-js-pagination";
import { fetchWrapper } from '../../helpers';



function Veiw_Cnic({rs}) {
	const [list,setList] = useState([])
	const [activePage, setActivePage] = useState(1);
	const [listCount, setListCount] = useState(0); 
	const [loading,setLoading] = useState(false) 			

	 useEffect(() => {
        loadList(rs)  
    
    }, [rs,activePage]);

	 const loadList = async (rs) => {
	  setLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/api/report-veiw?user_id=${rs.id}&page=${activePage}`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows);  
      setListCount(data.count[0].total_count)
      setLoading(false)
  	}

  	const onChangePaginate = (pageNumber) => {
	    setActivePage(pageNumber);
	    // to set offset
	    // console.log(pageNumber);
	};

	return(
		<>
		{loading &&
          <HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </HStack>
        }
		
		<div className="table_section">
          <TableContainer width="100%" mb="30px">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>ID</Th>
                  <Th>Name</Th>
                  <Th>Cnic</Th>
                  <Th>Mobile</Th>
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
 				{list.map((rs,index)=>{
                    return(
                           <Tr key={index}>
                            <Td>{rs.id}</Td>
                            <Td>{rs.name}</Td>
                         	<Td style={{color: rs.cnic.length < 15 ? 'red' : 'black' }}>{rs.cnic}</Td>
                          	<Td>{rs.mobile}</Td>
                           	
                       

                      
                            </Tr>
                          )
                  })}
                          
             
              </Tbody>
            </Table>
            <Pagination
               activePage={activePage}
               itemsCountPerPage={20}
               totalItemsCount={listCount}
               pageRangeDisplayed={5}
               onChange={onChangePaginate}
               innerClass="pagination"
               itemClass="page-item"
               activeLinkClass="page-link active"
               linkClass="page-link"
               prevPageText="Previous"
               nextPageText="Next"
              />
            
          </TableContainer>
        </div>


		</>
		)
}
export default Veiw_Cnic