import React,{useEffect,useState} from 'react';
import Pagination from "react-js-pagination";

import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
} from '@chakra-ui/react'
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import {Link} from 'react-router-dom';
import { fetchWrapper } from '../../helpers';
import moment from 'moment';
import * as Yup from 'yup';
import CustomModalLink from '../../components/CustomModalLink'
import CustomModal from '../../components/CustomModal'
import {InputText,Form,SelectFieldSearch} from '../../components/form'
import Create from "./Create"
import Edit from "./Edit"
import { toast } from "react-toastify";




function List(){
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [activePage, setActivePage] = useState(1);
	const [listCount, setListCount] = useState(0); 
	const [loading,setLoading] = useState(false) 
  	const [list,setList] = useState([])



  useEffect(() => {
        loadList()  
             
    }, [activePage]);


  	const loadList = async () => {
      setLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/api/depart-list?page=${activePage}`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows);
      setListCount(data.count);
      setLoading(false)
  	}

	const onChangePaginate = (pageNumber) => {
	    setActivePage(pageNumber);
	    // to set offset
	    console.log(pageNumber);
	};


 	const meClose = () => {
  		
		   onClose();	   
	}

	const meEdit = (rs) => {
		setIsOpenEdit(true);
		setModalEditView(<Edit rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)		
	}

	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}
	const meDelete = async (rs) => {
			const aa = window.confirm("Are you sure delete this recored");
			if(aa){
				setLoading(true)
				const url = `${process.env.REACT_APP_API_URL}/api/depart-delete/${rs.id}`;
	  		 	await fetchWrapper.delete(url)
		  		 loadList()
		  		 setLoading(false)
		  		 toast(`Record has been deleted `, {
		        type: "info"
		      	});
			}
	}


  return(
    <>
     <CustomModalLink
          showModalButtonText="Edit"
          modalHeader="Edit"
       		isOpenEdit={isOpenEdit}
       		onCloseEdit={meCloseEdit}
       		onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />


      <div className="body_section">
       {loading &&
          <HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </HStack>
        }
        
        <HStack justifyContent="space-between" mb="25px">
          <Box>
            <Text fontSize="24px" fontWeight="600" color="#000000" my="0px" fontFamily="'Open Sans', sans-serif">Departs</Text>
          </Box>
          	<Box>
				<CustomModal
	            showModalButtonText="Add New Depart"
	            modalHeader="Create Depart"
	           	isOpen={isOpen}
	           	onClose={onClose}
	           	onOpen={onOpen}
	            modalBody=<Create meClose={meClose}  loadList={loadList} />
	            />
			</Box>     
        </HStack>
                           

        <div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Name</Th>
                
                 
                  <Th></Th>


                  

                  
                </Tr>
              </Thead>

              <Tbody fontSize="14px">

	              {list.map((rs,index)=>{
	                    return(
	                           <Tr key={index} > 
	                            <Td>{rs.name}</Td>
	                            
	                            
	                          	<Td>
	                          		<HStack mb="5px" justifyContent="center" >
						        		 <a  
						        		 
						        		 onClick={() => meEdit(rs)}>
									        <BsPencil />
									      </a>  
									      <a  
						        		 onClick={() => meDelete(rs)}>
									        
									        <BsFillTrashFill />
									      </a>
									</HStack> 
	                          	</Td>
	                      
	                            </Tr>
	                          )
	                  })}
            
              </Tbody>
             
              
            </Table>
            <Pagination
               activePage={activePage}
               itemsCountPerPage={100}
               totalItemsCount={listCount}
               pageRangeDisplayed={100}
               onChange={onChangePaginate}
               innerClass="pagination"
               itemClass="page-item"
               activeLinkClass="page-link active"
               linkClass="page-link"
               prevPageText="Previous"
               nextPageText="Next"
              />
          </TableContainer>
        </div>
      </div>

      
    </>
    )
}

export default List;