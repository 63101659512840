import React,{useEffect,useState} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Input,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner
} from '@chakra-ui/react'
import Header from '../components/Header'
import MobileHeader from '../components/mobileHeader'
import "../index.css";
import { fetchWrapper } from '../helpers';
import Town from './chart/Town-Summary.chart'
import {InputText,Form,SelectFieldSearch,SelectField} from '../components/form'
import * as Yup from 'yup';
import moment from 'moment';
import  img1 from '../images/First.png';
import  img2 from '../images/Second.png';
import  img3 from '../images/Third.png';



function Town_Summary(){
	const [data,setData]= useState()
	const [loading,setLoading] = useState(false) 
	const [townList,setTownList] = useState([])
	const [formData, setFormData] = useState({
    town_id:'',
    
    
  })

  const FormSchema = Yup.object().shape({
     town_id: Yup.string().required('Required')
  });


	useEffect(()=>{
		const token = localStorage.getItem('user');

	    // If the token exists, decode it to get user data
	    let isSuperAdmin;
	    let userTownId
	    if (token) {
	      const decodedToken = decodeToken(token);
	       isSuperAdmin = decodedToken.Userdata.superadmin === 1;
	       userTownId = decodedToken.Userdata.town_id;
	    }
		LoadTownChart()
		loadListfilter(isSuperAdmin,userTownId)

	},[])

	const decodeToken = (token) => {
      const decodedString = atob(token.split('.')[1]);
      const decodedObject = JSON.parse(decodedString);
      // console.log(decodedObject)
      return decodedObject;
    };

	 	const LoadTownChart = async(town_id = "",date="")=>{
	 			setLoading(true)	    
		 		const url = `${process.env.REACT_APP_API_URL}/api/total-summary?town_id=${town_id}&date=${date}`;
	      const data  = await fetchWrapper.get(url)
	      setData(data)
	      setLoading(false)
	      // console.log(data)
	     
		}
		const loadListfilter = async (isSuperAdmin,userTownId) => {
			
	    // console.log(userTownId)
	    const url = isSuperAdmin
        ? `${process.env.REACT_APP_API_URL}/api/user-list-by-town`
        : `${process.env.REACT_APP_API_URL}/api/user-list-by-town?town_id=${userTownId}`;

      // const url = `${process.env.REACT_APP_API_URL}/api/user-list-by-town`;
      const data  = await fetchWrapper.get(url)
      setTownList(data.rows);
      // console.log(data)
      
  }

  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
	  setLoading(true)
	  let data = values;
	  setFormData(values);

	  console.log(data);
	  await LoadTownChart(data.town_id, data.date);
	  setLoading(false)
	};

	



	return(
		<>
		{loading &&
      <HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </HStack>
    }

		{/* Hearder Section */}
		<Box className="d_mb_none">
			<Header />
		</Box>

		<Box className="d_desk_none">
			<MobileHeader/>
		</Box>


		{/* Body Section */}
		<div className="body_section">

			<Box mb="30px">
				 <Text fontSize="24px" fontWeight="600" color="#000000" my="0px" fontFamily="'Open Sans', sans-serif">Town Wise Summary</Text>
			</Box>



			{/* Desktop Code */}
			<HStack mb="30px" justifyContent="space-between" className="town_page d_mb_none">
				<Form
				  enableReinitialize
				  validationSchema={FormSchema}
				  initialValues={formData}
				  onSubmit={onSubmit}
				>
				  <HStack>
				  

				  	<Box w="200px" float="right">
				      <Input
				        name="date"
				        label="Date"
				        type="date"
				        onChange={(e) => {setFormData((prevData) => ({ ...prevData, date: e.target.value }))}}
				        required
				      />
				    </Box>
				   
				    <Box className="voter-search">
				      <SelectFieldSearch name="town_id" options={townList}  />
				    </Box>
				    
				    <Box>
				      <Button
				        py="10px"
				        px="25px"
				        color="#fff"
				        bg="#2D7A06"
				        fontSize="14px"
				        fontWeight="500"
				        fontFamily="'Lato', sans-serif"
				        type="submit"
				      >
				        Apply Filter
				      </Button>
				    </Box>
				  </HStack>
				</Form>
			</HStack>

			{/* Mobile Code */}
			<HStack mb="30px" justifyContent="space-between" className="town_page d_desk_none ">
				<Form
				  enableReinitialize
				  validationSchema={FormSchema}
				  initialValues={formData}
				  onSubmit={onSubmit}
				>
				  <HStack alignItems="end">
				  	<Box>
				  	
					  	<Box w="200px" mb="20px">
					      <Input
					        name="date"
					        label="Date"
					        type="date"
					        onChange={(e) => {setFormData((prevData) => ({ ...prevData, date: e.target.value }))}}
					      />
					    </Box>
					
					    <Box className="voter-search">
					      <SelectFieldSearch name="town_id" options={townList}  />
					    </Box>
				    </Box>
				    
				    <Box>
				      <Button
				        py="10px"
				        px="25px"
				        color="#fff"
				        bg="#2D7A06"
				        fontSize="14px"
				        fontWeight="500"
				        fontFamily="'Lato', sans-serif"
				        type="submit"
				      >
				        Apply Filter
				      </Button>
				    </Box>
				  </HStack>
				</Form>
			</HStack>



			{/*Desktop Code*/}
			<HStack mb="30px" className="d_mb_none">
					

					<Box borderWidth="1px" borderColor="#ccc" w="321px" me="15px" h="142px">
						<HStack justifyContent="space-between">
							<Box py="10px" px="15px" >
							<Text fontSize="36px" fontWeight="600" color="#000" mb="0" >{data?.TownWiseSearchUser[0]?.voter ?? 0}</Text>
							<Text color="#000000" fontWeight="600" fontSize="14px">{data?.TownWiseSearchUser[0]?.user_name ?? ''}</Text>
						</Box>
						<Box>
							<Image src={img1}/>
						</Box>
						</HStack>
					</Box>


					<Box borderWidth="1px" borderColor="#ccc" w="321px" me="15px" h="142px">
						<HStack justifyContent="space-between">
							<Box py="10px" px="15px">
							<Text fontSize="36px" fontWeight="600" color="#000" mb="0">{data?.TownWiseSearchUser[1]?.voter ?? 0}</Text>
							<Text color="#000000" fontWeight="600" fontSize="14px">{data?.TownWiseSearchUser[1]?.user_name ?? ''}</Text>
						</Box>
						<Box>
							<Image src={img2}/>
						</Box>
						</HStack>
					</Box>

					<Box borderWidth="1px" borderColor="#ccc" w="321px" me="15px" h="142px">
						<HStack justifyContent="space-between">
							<Box py="10px" px="15px">
							<Text fontSize="36px" fontWeight="600" color="#000" mb="0">{data?.TownWiseSearchUser[2]?.voter ?? '0'}</Text>
							<Text color="#000000" fontWeight="600" fontSize="14px">{data?.TownWiseSearchUser[2]?.user_name ?? ''}</Text>
						</Box>
						<Box>
							<Image src={img3}/>
						</Box>
						</HStack>
					</Box>

					<Box borderWidth="1px" borderColor="#ccc" w="225px" me="15px">
						<Box py="8px" px="15px" borderBottomWidth="1px">
							<Text fontSize="24px" fontWeight="600" color="#000" mb="0">{data?.VoterTowncount[0]?.voter ?? '0'}</Text>
							<Text color="#000000" fontWeight="600" fontSize="14px" mb="0px" mt="-4px">Total Voters</Text>
						</Box>
						<Box py="8px" px="15px">
							<Text fontSize="24px" fontWeight="600" color="#000" mb="0">{data?.TownwiseUserCount[0]?.user_count ?? '0'}</Text>
							<Text color="#000000" fontWeight="600" fontSize="14px" mb="0px" mt="-4px">Total Users</Text>
						</Box>
					</Box>

					
				</HStack>

				{/*Mobile Codess*/}
				<HStack mb="20px" className="d_desk_none">
					

					<Box borderWidth="1px" borderColor="#ccc" w="100%" me="15px" h="142px">
						<HStack justifyContent="space-between">
							<Box py="10px" px="15px" >
							<Text fontSize="36px" fontWeight="600" color="#000" mb="0" >{data?.TownWiseSearchUser[0]?.voter ?? 0}</Text>
							<Text color="#000000" fontWeight="600" fontSize="14px">{data?.TownWiseSearchUser[0]?.user_name ?? ''}</Text>
						</Box>
						<Box>
							<Image src={img1}/>
						</Box>
						</HStack>
					</Box>


					<Box borderWidth="1px" borderColor="#ccc" w="100%" me="15px" h="142px" mt="10px">
						<HStack justifyContent="space-between">
							<Box py="10px" px="15px">
							<Text fontSize="36px" fontWeight="600" color="#000" mb="0">{data?.TownWiseSearchUser[1]?.voter ?? 0}</Text>
							<Text color="#000000" fontWeight="600" fontSize="14px">{data?.TownWiseSearchUser[1]?.user_name ?? ''}</Text>
						</Box>
						<Box>
							<Image src={img2}/>
						</Box>
						</HStack>
					</Box>

					<Box borderWidth="1px" borderColor="#ccc" w="100%" me="15px" h="142px" mt="10px">
						<HStack justifyContent="space-between">
							<Box py="10px" px="15px">
							<Text fontSize="36px" fontWeight="600" color="#000" mb="0">{data?.TownWiseSearchUser[2]?.voter ?? '0'}</Text>
							<Text color="#000000" fontWeight="600" fontSize="14px">{data?.TownWiseSearchUser[2]?.user_name ?? ''}</Text>
						</Box>
						<Box>
							<Image src={img3}/>
						</Box>
						</HStack>
					</Box>

					<Box borderWidth="1px" borderColor="#ccc" w="100%" me="15px" mt="10px">
						<Box py="8px" px="15px" borderBottomWidth="1px">
							<Text fontSize="24px" fontWeight="600" color="#000" mb="0">{data?.VoterTowncount[0]?.voter ?? '0'}</Text>
							<Text color="#000000" fontWeight="600" fontSize="14px" mb="0px" mt="-4px">Total Voters</Text>
						</Box>
						<Box py="8px" px="15px">
							<Text fontSize="24px" fontWeight="600" color="#000" mb="0">{data?.TownwiseUserCount[0]?.user_count ?? '0'}</Text>
							<Text color="#000000" fontWeight="600" fontSize="14px" mb="0px" mt="-4px">Total Users</Text>
						</Box>
					</Box>

					
				</HStack>



			<HStack mb="30px" w="100%">
				<Box borderWidth="1px" borderColor="#ccc" w="100%">
						<Box borderWidth="1px" borderColor="#ccc" p="15px"> 
							<Town datalist={data}/>
						</Box>
					</Box>
			</HStack>


			<div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid" mb="50px">
              <Thead >
                <Tr>
                  <Th>Name</Th>
                  <Th>E-Mail ID</Th>
                  <Th>Town</Th>
                  <Th>District</Th>
                  <Th>Voters</Th>
                  {/*<Th>Joined On</Th>*/}
                  <Th></Th>
                 
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
              {data?.UserTown.length > 0 ? (
								  data.UserTown.map((rs, index) => (
								    <Tr key={index}>
								      <Td>{rs.name}</Td>
								      <Td>{rs.email}</Td>
								      <Td>{rs.town}</Td>
								      <Td>{rs.district}</Td>
								      <Td>{rs.voter_count}</Td>
								      {/*<Td>{moment(rs.createdAt).format('DD MMMM, YYYY')}</Td>*/}
								      <Td></Td>
								    </Tr>
								  ))
								) : (
								  <tr>
								    <td colSpan="6">No data</td>
								  </tr>
								)}
              </Tbody>
              
            </Table>
           
          </TableContainer>
        </div>

			
		</div>
		</>
	)
}

export default Town_Summary;