import React from 'react';
import {
    Formik,
    Form as FormikForm,
    Field,
    ErrorMessage,
    useFormikContext,
    useField,
    useFormik
} from 'formik';
import MySelect from './MySelect';


function SelectFieldSearch(props) {
    const { name, label, options,onChange,placeholder,customStyles } = props
     
     
    return (
        <>

        
       {label && <br />}

            {label && <label htmlFor={name}>{label} </label>}

             <Field component={MySelect} name={name} placeholder={placeholder}  options={options}/>
      
            
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />

            
        </>
    )
}

export default SelectFieldSearch;