import React,{useEffect,useState} from 'react';
import Pagination from "react-js-pagination";
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import {Link} from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons'
import { fetchWrapper } from '../../helpers';
import CustomModalLink from '../../components/CustomModalLink'
import CustomModal from '../../components/CustomModal'
import moment from 'moment';
import EditUser from './Edit'
import {InputText,Form,SelectFieldSearch} from '../../components/form'
import * as Yup from 'yup';



function List(){

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
  const [isOpenEdit,setIsOpenEdit] = useState(false)   
  const [modalEditView,setModalEditView] = useState(false)
  const [activePage, setActivePage] = useState(1);
  const [listCount, setListCount] = useState(0); 
  const [loading,setLoading] = useState(false)

  const [list,setList] = useState([])
  const [Distlist,setDistList] = useState([])
  const [Townlist,setTownList] = useState([])
  const [formData, setFormData] = useState({
   
    district_id:'',
    town_id:''
    
  });

  const FormSchema = Yup.object().shape({

     // district_id: Yup.string().required('Required'),
    // town_id: Yup.string().required('Required'),

  });

  useEffect(() => {
    loadList(formData.district_id,formData.town_id,activePage);  
    loadListDist()
    loadListTown()      
  }, [activePage,formData.district_id,formData.town_id]);

    const decodeToken = (token) => {
      const decodedString = atob(token.split('.')[1]);
      const decodedObject = JSON.parse(decodedString);
      // console.log(decodedObject)
      return decodedObject;
    };


  const loadList = async (district_id="",town_id="") => {
    setLoading(true)

     const token = localStorage.getItem('user');

    // If the token exists, decode it to get user data
    if (token) {
      const decodedToken = decodeToken(token);
      const isSuperAdmin = decodedToken.Userdata.superadmin === 1;
      const userTownId = decodedToken.Userdata.town_id;

      // If user is superadmin, use the provided town_id; otherwise, use user's town_id
      if (isSuperAdmin) {
        town_id = formData.town_id || town_id;
      } else {
        // town_id = userTownId;
        town_id = formData.town_id || userTownId;
      }
    }

    const url = `${process.env.REACT_APP_API_URL}/api/user-list?page=${activePage}&district_id=${district_id}&town_id=${town_id}`;
    const data  = await fetchWrapper.get(url)
    setList(data.rows);
    setListCount(data.count);
    setLoading(false)
  }

  const loadListDist = async () => {
      setLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/api/district-list`;
      const data  = await fetchWrapper.get(url)
      setDistList(data.rows);
      setLoading(false)
      
  }
  const loadListTown = async () => {
      setLoading(true)
      const token = localStorage.getItem('user');

      // If the token exists, decode it to get user data
      let isSuperAdmin;
      let userTownId
      if (token) {
        const decodedToken = decodeToken(token);
         isSuperAdmin = decodedToken.Userdata.superadmin === 1;
         userTownId = decodedToken.Userdata.town_id;
      }
      console.log(userTownId)
      const url = isSuperAdmin
        ? `${process.env.REACT_APP_API_URL}/api/user-list-by-town`
        : `${process.env.REACT_APP_API_URL}/api/user-list-by-town?town_id=${userTownId}`;

      // const url = `${process.env.REACT_APP_API_URL}/api/user-list-by-town`;
      const data  = await fetchWrapper.get(url)
      setTownList(data.rows);
      setLoading(false)
      
  }

  const toggleStatus = async(rs) => {

    // Update the status using the API
    // Replace the URL with the actual API endpoint
      const url = `${process.env.REACT_APP_API_URL}/api/user-update/${rs.id}`;
      const value = {
      status_id  :rs.status_id ? 0 : 1
      }
     const data  = await fetchWrapper.put(url,value)
     setLoading(true)
     loadList()
     setLoading(false)
     // console.log(url)
     
  };

  const meClose = () => {
      
       onClose();
       
  }

  const meCloseEdit = () => {
      
       setIsOpenEdit(false);
       
  }

  const meEdit = (rs) => {
    setIsOpenEdit(true);
    setModalEditView(<EditUser rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)   
  }

  const onChangePaginate = (pageNumber) => {
    setActivePage(pageNumber);
    // to set offset
    console.log(pageNumber);
  };

  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
    setLoading(true)
    setFormData(values)
    console.log(values)
    setActivePage(1)
    setLoading(false)
  }


  const isSuperAdmin = () => {
    const token = localStorage.getItem('user');
    if (token) {
      const decodedToken = decodeToken(token);
      return decodedToken.Userdata.superadmin === 1;
    }
    return false;
  };

 
  return(
    <>

    {loading &&
          <HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </HStack>
    }


    <CustomModalLink
          showModalButtonText="Edit"
          modalHeader="User Edit"
          isOpenEdit={isOpenEdit}
          onCloseEdit={meCloseEdit}
          onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />

    



      <div className="body_section">

      <HStack justifyContent="space-between" mb="25px">
        <Box>
          <Text fontSize="24px" fontWeight="600" color="#000000" my="0px" fontFamily="'Open Sans', sans-serif">Users</Text>
        </Box>

            
      </HStack>
      
        {/*Desktop Code*/}
     
        <Box mb="10px" className="d_mb_none">
          <Form
                    enableReinitialize
                    validationSchema={FormSchema}
                    initialValues={formData}
                    onSubmit={onSubmit}
                            
                    
         >
          <HStack>
            <HStack>
          {isSuperAdmin() && (
            <Box mt="15px" mb={3} className ="voter-search">
              <SelectFieldSearch 
                   name="district_id"
                   options={Distlist}
                 />
            </Box>
          )}
          <Box mt="15px" mb={3} className ="voter-search">
            <SelectFieldSearch 
                 name="town_id"
                 options={Townlist}
               />
          </Box>

            </HStack>
            <Box>
              <Button py="10px" px="25px" color="#fff" bg="#2D7A06" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif" 
                      
                      
                      type='submit'
                    >
                     Apply Filter
                  </Button>
            </Box>

            </HStack>
            </Form>
        </Box>
        



        {/*Mobile Code*/}
        {isSuperAdmin() && (
          <Box mb="10px" className="d_desk_none">
            <Form
                      enableReinitialize
                      validationSchema={FormSchema}
                      initialValues={formData}
                      onSubmit={onSubmit}
                              
                      
           >
            <HStack alignItems="end">
                <Box>
                
                <Box mt="15px" mb={3} className ="voter-search">
                  <SelectFieldSearch 
                       name="district_id"
                       options={Distlist}
                     />
                </Box>
                <Box mt="15px" className ="voter-search">
                  <SelectFieldSearch 
                       name="town_id"
                       options={Townlist}
                     />
                </Box>

              </Box>
              <Box>
                <Button py="10px" px="25px" color="#fff" bg="#2D7A06" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif" 
                        
                        
                        type='submit'
                      >
                       Apply Filter
                    </Button>
              </Box>

              </HStack>
              </Form>
          </Box>
        )}  
        
        

        <div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Name</Th>
                  <Th>E-Mail ID</Th>
                  <Th>Contact</Th>
                  <Th>Town</Th>
                  <Th>District</Th>
                  <Th>Voters</Th>
                  <Th>Joined On</Th>
                  <Th>Status</Th>
                  <Th></Th>
                  <Th></Th>
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
              {list.map((rs,index)=>{
                    return(
                           <Tr key={index}>
                            <Td>{rs.name}</Td>
                            <Td>{rs.email}</Td>
                            <Td>{rs.contact_no}</Td>
                            <Td>{rs.town}</Td>
                            <Td>{rs.district}</Td>
                            <Td>{rs.voter_count}</Td>
                            <Td>{moment(rs.createdAt).format('DD MMMM, YYYY')}</Td>

                            <Td style={{fontWeight:"700",fontSize:"14px"}}>
                              <span style={{ color: rs.status_id === 1 ? 'green' : 'red'}}>
                                {rs.status_id === 1 ? 'Active' : 'In-Active'}
                              </span>
                            </Td>
                            <Td style={{color:"#0085FF"}}>
                            <Menu>
                              <MenuButton fontSize="14px" >
                              Action
                                
                                  <ChevronDownIcon />
                              </MenuButton>

                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                                <MenuItem  h="16px" fontSize="12px" onClick={() => meEdit(rs)}>Edit</MenuItem>
                                 <MenuDivider />
                                <MenuItem h="16px" fontSize="12px" onClick={() => toggleStatus(rs)} >{rs.status_id === 0 ? "Grant Access":"Revoke Access"}</MenuItem>
                                
                              </MenuList>
                            </Menu>
                            </Td>
                            <Td></Td>

                      
                            </Tr>
                          )
                  })}
              </Tbody>
              
            </Table>
            <Pagination
               activePage={activePage}
               itemsCountPerPage={100}
               totalItemsCount={listCount}
               pageRangeDisplayed={5}
               onChange={onChangePaginate}
               innerClass="pagination"
               itemClass="page-item"
               activeLinkClass="page-link active"
               linkClass="page-link"
               prevPageText="Previous"
               nextPageText="Next"
              />
          </TableContainer>
        </div>
      </div>

      
    </>
    )
}

export default List;