import React,{useEffect,useState} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner
} from '@chakra-ui/react'
import {Link} from 'react-router-dom';
import CustomModalLink from '../../components/CustomModalLink'
import CustomModal from '../../components/CustomModal'
import Createtown from './Createtown'
import Createdist from './Createdistrict'
import { fetchWrapper } from '../../helpers';
import { toast } from "react-toastify";
import EditTown from './TownEdit'
import { BsPencil, BsFillTrashFill } from "react-icons/bs";




function TownList({district_id,show}){
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
  const [isOpenEdit,setIsOpenEdit] = useState(false)   
  const [modalEditView,setModalEditView] = useState(false)

  const [loading,setLoading] = useState(false)
 
  const [list,setList] = useState([]);
  

  useEffect(() => {
      
        loadTownList();        
        

    }, [district_id,show]);

 
 
   const loadTownList= async () => {
  
      const url = `${process.env.REACT_APP_API_URL}/api/town-list?district_id=${district_id}`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows);
  }

  const meClose = () => {
      
       onClose();
       
  }

  const meCloseEdit = () => {
      
       setIsOpenEdit(false);
       
  }

  const meEdit = (rs) => {
    setIsOpenEdit(true);
    setModalEditView(<EditTown rs={rs} meCloseEdit={meCloseEdit}  loadList={loadTownList} />)   
  }

  const meDelete = async (rs) => {
      const aa = window.confirm("Are you sure delete this record");
      if(aa){
        setLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/api/town-delete/${rs.id}`;
        await fetchWrapper.delete(url)
         loadTownList()
         setLoading(false)
         toast(`Record has been deleted `, {
          type: "info"
        });
      }
  }


  return(
    <>
    {loading &&
      <HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </HStack>
    }

    <CustomModalLink
          showModalButtonText="Edit"
          modalHeader="Town Edit"
          isOpenEdit={isOpenEdit}
          onCloseEdit={meCloseEdit}
          onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />
               
                
                           {list.map((rs,index) => (
                           <Tr key={index}>
                              <Td >

                                {rs.name}
                              </Td>
                              <Td>
                                {rs.User?.name}
                              </Td>
                              <Td>
                              <HStack mb="5px" mr="65px" justifyContent="right" >
                                <a 
                                 onClick={() => meEdit(rs)}>
                                  <BsPencil />
                                </a>  
                                <a  
                                 onClick={() => meDelete(rs)}>
                                  
                                  <BsFillTrashFill />
                                </a>
                              </HStack>
                              </Td>
                              
                            </Tr>
                              ))}
       
                        
    </>
    )
}

export default TownList;