import React,{useEffect,useState} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner
} from '@chakra-ui/react'
import {Link} from 'react-router-dom';

import { fetchWrapper } from '../helpers';







function Mob_TownList({district_id,show}){
 



 
  const [list,setList] = useState([]);
  
  

  useEffect(() => {
      
        loadTownList();        
        

    }, [district_id,show]);

 
 
   const loadTownList= async () => {
  
      const url = `${process.env.REACT_APP_API_URL}/api/Respon-town-voter?district_id=${district_id}`
      const data  = await fetchWrapper.get(url)
      setList(data.TownVoterCountRespon);
  }

 
  return(
    <>

                <div className="">
                  <TableContainer width="100%">
                    <Table variant='simple' width="100%" borderWidth="1px" borderColor="" borderStyle="solid">
                       
                          <Tbody>
                           {list.map((rs,index) => (
                           <Tr key={index} borderBottomWidth="2px">
                              <Td style={{width: '60%'}}>

                                {rs.name}
                              </Td>
                              <Td style={{width: '40%',textAlign: 'center'}}>
                               {rs.voter}
                              </Td>
                              
                            </Tr>
                              ))}
       
                          </Tbody>

                  </Table>
                </TableContainer>
              </div>
    </>
    )
}

export default Mob_TownList;