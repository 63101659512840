import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import CustomModalLink from '../../components/CustomModalLink'
import CustomModal from '../../components/CustomModal'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField} from '../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../helpers';
import {
   
    Field,
    
} from 'formik';



function Create({loadList,meClose}){
	
	
	const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    superadmin: '',
    
    confirmPassword:''
    
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   email: Yup.string().required('Required'),
	   password: Yup.string().required('Required'),
	 	confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords not match').required('Required'),
	  superadmin: Yup.string().required('Required'),
	});

  const [townList,setTownList] = useState([]);

	useEffect(() => {
        loadTownList()
       
       
        
    }, []);

  const loadTownList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/user-list-by-town`;
      const data  = await fetchWrapper.get(url)
      // console.log(data)
      setTownList(data)
  }

  


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/web-user-create`;
  		 
  		try{
  		 	await fetchWrapper.post(url,data)
  		 	loadList()
	  		 meClose();
	  		 toast(`Record has been saved `, {
		        type: "info"
		      });
	  	}
  		catch{
  		 	toast(`Email already exits `, {
	        type: "info"
	      	});
  		 	meClose();
  		 }
  		 
	}

	
	const defaultAccessList = {rows:[{id:1,name:'Yes'},{id:0,name:'No'}]}

	

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="name" title="Full Name"  />
			 	<InputText name="email" title="Eamil"  />
			 	<InputText inputProps={{type:'password'}} name="password" title="Password"   />
			 	<InputText inputProps={{type:'password'}} name="confirmPassword" title="Re-type Password"   />
			 	<SelectField list={defaultAccessList} name="superadmin" title="SuperAdmin"  />
			 	<Field
				    name="town_id"
				    id="town"
				    placeholder="Select town"
				    title="Town Name"
				    isMulti={true}
				    component={MultiSelectField}
				    options={

				    	townList?.rows?.map(item => {

				    		return {label:item.name,value:item.id}
				    	})
				    }
				/>
		         
		         <Button py="10px" px="25px" color="#fff" bg="#2D7A06" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>


		          		

			</Form>

			</>

	)
}

export default Create;