import React,{useState,useEffect} from 'react';
import { HStack,
	  Box,
	  Image,
	  Text,
	  Menu,
	  MenuButton,
	  MenuList,
	  MenuItem,
	  Button } from '@chakra-ui/react';

import { ChevronDownIcon } from '@chakra-ui/icons'


import Headerlogo from '../images/mqm.png';
import userimg from '../images/user_img.png';
import { Link ,useLocation,useNavigate} from "react-router-dom";
import "../index.css";

function Header(){

	  const [email, setEmail] = useState('');
	  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	  const location = useLocation();
	  const navigate = useNavigate()
	

	useEffect(() => {
	    // Check if the token is present in local storage
	    const token = localStorage.getItem('user');


	    if (token) {
	      const decodedToken = decodeToken(token);
	      const Userdata = decodedToken.Userdata;
	      // console.log(Userdata)

	      // Set the email state
	      setEmail(Userdata.email);
	      setIsSuperAdmin(Userdata.superadmin === 1)
	    }
	  }, []);

	const decodeToken = (token) => {
	    const decodedString = atob(token.split('.')[1]);
	    const decodedObject = JSON.parse(decodedString);
	    // console.log(decodedObject)
	    return decodedObject;
	  };

	  const Logout = ()=>{
	  	localStorage.removeItem('user')
	  	navigate("/")
	  }
	
	const link = (view)=>{
		if(view == 'cnic'){
			navigate("/cnic")
		}
		if(view == 'contact'){
			navigate("/contact")
		}
	}
	

	return(
		<>
		<HStack px="70px" py="10px" justifyContent="space-between">
		<Box>
		<Link to="/dashboard">
			<Image src={Headerlogo} alt='MQM' w="49.44px" h="50px"  />
		</Link>
			
				
		</Box>
		<Box>
			<HStack>
				<Box>
					<Menu>
					  <MenuButton textAlign="left"   fontSize="14px" >
					  <Text mb="0">Welcome</Text>
					  <HStack id="Header_User">
					
					  <Box>{email}</Box>
					  <Box><ChevronDownIcon boxSize={6}  /></Box>

					

					  
					  
					      
					  </HStack>
					    
					  </MenuButton>

					  <MenuList>
					    <MenuItem>Privacy Policy</MenuItem>
					    <MenuItem onClick={()=> Logout()}>Logout</MenuItem>
					    
					  </MenuList>

					</Menu>
				</Box>
			

				<Box>
				
					<Image src={userimg}  style={{borderRadius:"50%"}} w="48.54px" h="48px"  />
				</Box>
			
			</HStack>
		</Box>
		
		</HStack>
		
		<HStack h="50px" bg="#2D7A06" py="10px" px="50px" justifyContent="space-between" color="#FFFFFF" fontSize="14px" className="header_section">

			

			<Box ml="20px">
				<HStack>
					<Box me="30px">
						<Link to="/dashboard" className={location.pathname === "/dashboard" ? "active" : ""} >Dashboard</Link>
					</Box>

					<Box me="30px">
						<Link to="/voter" className={location.pathname === "/voter" ? "active" : ""} >Voter Contacts</Link>
					</Box>

					<Box me="30px">
						<Link to="/user" className={location.pathname === "/user" ? "active" : ""}>Users</Link>
					</Box>

					
					{isSuperAdmin && (
					<Box me="30px">
						<Link to="/district" className={location.pathname === "/district" ? "active" : ""}>District & Towns</Link>
					</Box>
					)}

					{isSuperAdmin && (
					<Box me="30px">
						<Link to="/depart" className={location.pathname === "/depart" ? "active" : ""}>Depart</Link>
					</Box>
					)}
					<Box  me="30px">
						<Link to="/town-summary" className={location.pathname === "/town-summary" ? "active" : ""}>Town-Summary</Link>
					</Box>

					{isSuperAdmin && (
		              <Box me="30px">
		                <Link to="/web-user" className={location.pathname === "/web-user" ? "active" : ""}>
		                  Web User
		                </Link>
		              </Box>
		            )}


					
		            <Box>
						<Menu>
						  <MenuButton textAlign="left"   fontSize="14px" >
						  <HStack id="Header_User">
						
						  <Box>Reports</Box>
						  <Box><ChevronDownIcon boxSize={6}  /></Box>

						

						  
						  
						      
						  </HStack>
						    
						  </MenuButton>

						  <MenuList>
						    <MenuItem mb="2px"  onClick={()=> link('cnic')}> 
						    	<Link to="#">Cnic Error</Link>
						    </MenuItem>
						    <hr />
						    <MenuItem mb="2px" onClick={()=> link('contact')}>
						    	<Link to="#">Contact Error</Link>
						    </MenuItem>
						    
						  </MenuList>

						</Menu>
					</Box>
					


					
				</HStack>
			</Box>
		</HStack>
		</>
	);
}

export default Header;