import React,{useEffect,useState} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner
} from '@chakra-ui/react'
import Header from '../components/Header'
import MobileHeader from '../components/mobileHeader'
import "../index.css";
import { fetchWrapper } from '../helpers';
import DistrictChart from './chart/district.chart'
import UserChart from './chart/user.chart'
import DateVoter from './chart/date-voter.chart.js'
import DateUser from './chart/date-user.chart.js'
import TownChart from './chart/Town.chart'
import Town_UserChart from './chart/TownUser.chart'
import Mob_TownList from "./mobileTown.js"
import Mob_TownUserList from "./mobileTownUser.js"


function Dashboard(){
	const [totalVoter,settotalVoter]= useState()
	const [data,setData]= useState()
	const [listdist,setListdistrict] = useState([]);
	const [totalUser,settotalUser]= useState()
	const [show,setShow] = useState(false)


	useEffect(()=>{

		Dashboard_Api()
		loadDistrictList()

	},[show])
	const decodeToken = (token) => {
      const decodedString = atob(token.split('.')[1]);
      const decodedObject = JSON.parse(decodedString);
      // console.log(decodedObject)
      return decodedObject;
    };

	 	const Dashboard_Api = async(town_id="")=>{
	 		const token = localStorage.getItem('user');

    // If the token exists, decode it to get user data
    if (token) {
      const decodedToken = decodeToken(token);
      const isSuperAdmin = decodedToken.Userdata.superadmin === 1;
      const userTownId = decodedToken.Userdata.town_id;

      // If user is superadmin, use the provided town_id; otherwise, use user's town_id
      if (isSuperAdmin) {
        town_id = '';
      } else {
        town_id = userTownId;
      }
    }
		 		const url = `${process.env.REACT_APP_API_URL}/api/total?town_id=${town_id}`;
	      const data  = await fetchWrapper.get(url)
	      settotalVoter(data.TotalVoters)
	      settotalUser(data.TotalUser)
	      console.log(data)
	      setData(data)
		}

		const loadDistrictList= async () => {

      const url = `${process.env.REACT_APP_API_URL}/api/district-list`;
      const data  = await fetchWrapper.get(url)
      setListdistrict(data.rows);
      // console.log(data.rows)
  }

  const cnicErr = data?.CnicCounterr[0].total_cnic_count
  const contactErr = data?.ContactCounterr[0].total_mobile_count
	const Team = data?.CountTeam[0].Team_contact



	return(
		<>

		<Box className="d_mb_none">
			<Header />
		</Box>

		<Box className="d_desk_none">
			<MobileHeader/>
		</Box>

			<div className="body_section">
			<HStack mb="30px">
					<Text fontSize="24px" fontWeight="600" color="#000000" fontFamily="'Open Sans', sans-serif">Dashboard</Text>
			</HStack>

				<HStack mb="30px" >
					<Box borderWidth="2px" borderColor="#ccc" w="250px" me="10px">
						<Box py="10px" px="10px">
							<Text fontSize="36px" fontWeight="600" color="#000">{totalVoter}</Text>
						</Box>

						<Box py="0px" px="10px">
							<Text color="#000000" fontSize="14px" fontWeight="700">Total Voters</Text>
						</Box>
					</Box>
					<Box borderWidth="2px" borderColor="#ccc" w="250px" me="15px">
						<Box py="10px" px="10px">
							<Text fontSize="36px" fontWeight="600" color="#000">{totalUser}</Text>
						</Box>

						<Box py="0px" px="10px">
							<Text color="#000000" fontSize="14px" fontWeight="700">Total Users</Text>
						</Box>
					</Box>

					<Box borderWidth="2px" borderColor="#ccc" w="250px" me="10px">
						<Box py="10px" px="10px">
							<Text fontSize="36px" fontWeight="600" color="#000">{Team}</Text>
						</Box>

						<Box py="0px" px="10px">
							<Text color="#000000" fontSize="14px" fontWeight="700">Without Smartphone Users</Text>
						</Box>
					</Box>
					<Box borderWidth="2px" borderColor="#ccc" w="250px" me="15px">
						<Box py="10px" px="10px">
							<Text fontSize="36px" fontWeight="600" color="#000">{contactErr}</Text>
						</Box>

						<Box py="0px" px="10px">
							<Text color="#000000" fontSize="14px" fontWeight="700">Incorrect Contact #</Text>
						</Box>
					</Box>


					<Box borderWidth="2px" borderColor="#ccc" w="250px" me="10px">
						<Box py="10px" px="10px">
							<Text fontSize="36px" fontWeight="600" color="#000">{cnicErr}</Text>
						</Box>

						<Box py="0px" px="10px">
							<Text color="#000000" fontSize="14px" fontWeight="700">Incorrect CNIC’s</Text>
						</Box>
					</Box>
					

				</HStack>

				{/*Desktop Code*/}


				<HStack mb="30px" className="d_mb_none" w="100%">
					<Box borderWidth="1px" borderColor="#ccc" me="0px" w="50%">
						<Box borderWidth="1px" borderColor="#ccc" p="15px"> 
							{/*<Text fontSize="14px" fontWeight="500" color="#000000" mb="20px">District Wise Voters</Text>*/}
						<DistrictChart datalist={data}/>
						</Box>
					</Box>
					

					<Box borderWidth="1px" borderColor="#ccc" w="50%">
						<Box borderWidth="1px" borderColor="#ccc" p="15px"> 
							{/*<Text fontSize="14px" fontWeight="500" color="#000000" mb="20px">District Wise Voters</Text>*/}
						<UserChart datalist={data}/>
						</Box>
					</Box>
				</HStack>

				{/*Mobile Code*/}

				<Box mb="30px" className="d_desk_none">
					<Box borderWidth="1px" borderColor="#ccc" mb="30px" w="100%">
						<Box borderWidth="1px" borderColor="#ccc" p="15px"> 
							{/*<Text fontSize="14px" fontWeight="500" color="#000000" mb="20px">District Wise Voters</Text>*/}
						<DistrictChart datalist={data}/>
						</Box>
					</Box>
					

					<Box borderWidth="1px" borderColor="#ccc" w="100%">
						<Box borderWidth="1px" borderColor="#ccc" p="15px"> 
							{/*<Text fontSize="14px" fontWeight="500" color="#000000" mb="20px">District Wise Voters</Text>*/}
						<UserChart datalist={data}/>
						</Box>
					</Box>
				</Box>


				{/*Desktop Code*/}
				<Box className="d_mb_none">
						<Box borderWidth="1px" borderColor="#ccc" w="100%">
							<Box borderWidth="1px" borderColor="#ccc" p="15px"> 
								<TownChart datalist={data}/>
							</Box>
						</Box>

						<Box borderWidth="1px" borderColor="#ccc"  mt="50px" mb="30px" w="100%">
							<Box borderWidth="1px" borderColor="#ccc" p="15px"> 
								<Town_UserChart datalist={data}/>
							</Box>
						</Box>

						<Box mb="30px">
							<Box borderWidth="1px" borderColor="#ccc" w="100%">
								<Box borderWidth="1px" borderColor="#ccc" p="15px"> 
									<DateVoter datalist={data}/>
								</Box>
							</Box>
						</Box>

						<Box mb="30px">
							<Box borderWidth="1px" borderColor="#ccc" w="100%">
								<Box borderWidth="1px" borderColor="#ccc" p="15px"> 
									<DateUser datalist={data}/>
								</Box>
							</Box>
						</Box>

				</Box>


				



				{/*Mobile Code*/}
				<Box mb="30px" className="d_desk_none">
				<Text fontSize="14px" fontWeight="600" color="#000000" mb="10px">Town Wise Voters</Text>
				{listdist.map(district => (
                <div key={district.id}>

                <div>
                  <TableContainer width="100%" mb="10px">
					            <Table variant='simple' className="mobile-table" width="100%" borderWidth="1px" borderColor="#CCCCCC"  h="40px">
					              <Thead bg="#00B750" borderStyle="solid">
					                <Tr style={{border:"1px solid #CCCCCC"}}>
					                  <Th style={{padding:"10px 30px",color:"#FFFFFF",width: '60%'}}>{district.name}</Th>
					                  <Th style={{color:"#FFFFFF",width: '40%',textAlign: 'center'}}>Count</Th>                  
					                </Tr>
					              </Thead>

					              <Tbody fontSize="14px">		                    
		                           <Tr  borderBottomWidth="2px" borderColor="#CCCCCC" >
		                            <Td style={{padding: '0px'}} colspan="2">
		                            	<Mob_TownList show={show} district_id={district.id} />

		                            </Td>
		                            
		                            </Tr>
            
					              </Tbody>
					             
					              
					            </Table>
					        </TableContainer>
              </div>
            </div>
              ))}
						
					

						<Box w="100%">
							<Text fontSize="14px" fontWeight="600" color="#000000" mb="10px">Town Wise Users</Text>
					        {listdist.map(district => (
                <div key={district.id}>

                <div>
                  <TableContainer width="100%" mb="10px">
					            <Table variant='simple' className="mobile-table" width="100%" borderWidth="1px" borderColor="#CCCCCC"  h="40px">
					              <Thead bg="#00B750" borderStyle="solid">
					                <Tr style={{border:"1px solid #CCCCCC"}}>
					                  <Th style={{padding:"10px 30px",color:"#FFFFFF",width: '60%'}}>{district.name}</Th>
					                  <Th style={{color:"#FFFFFF",width: '40%',textAlign: 'center'}}>Count</Th>                  
					                </Tr>
					              </Thead>

					              <Tbody fontSize="14px">		                    
		                           <Tr  borderBottomWidth="2px" borderColor="#CCCCCC" >
		                            <Td style={{padding: '0px'}} colspan="2">
		                            <Mob_TownUserList show={show} district_id={district.id} />

		                            </Td>
		                            
		                            </Tr>
            
					              </Tbody>
					             
					              
					            </Table>
					        </TableContainer>
              </div>
            </div>
              ))}
						</Box>


				</Box>

	


				


				{/*Desktop Code*/}
				<HStack mb="30px" alignItems="top" className="d_mb_none">
					<Box mr="2%" >
						<Text fontSize="14px" fontWeight="600" color="#000000" mb="20px">Top Voters Added</Text>
				        <TableContainer width="100%">
				            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC"   w="369px"  h="40px">
				              <Thead bg="#CCCCCC" borderStyle="solid">
				                <Tr style={{border:"1px solid #CCCCCC"}}>
				                  <Th style={{padding:"10px 30px",color:"black"}}>User Name</Th>
				                  <Th style={{padding:"10px 30px",color:"black"}}>Count</Th>                  
				                </Tr>
				              </Thead>

				              <Tbody fontSize="14px">

				              {data?.UserVoterCount.map((rs,index)=>{
				                    return(
				                           <Tr key={index} borderBottomWidth="2px" borderColor="#CCCCCC" >
				                            <Td style={{padding:"10px 30px"}}>{rs.name}</Td>
				                            <Td style={{padding:"10px 30px"}}>{rs.voter_count}</Td>
				                      
				                            </Tr>
				                          )
				                  })}    
				              </Tbody>
				             
				              
				            </Table>
				        </TableContainer>
					</Box>


					<Box>
						<Text fontSize="14px" fontWeight="600" color="#000000" mb="20px">No Voter Added</Text>
				        <TableContainer width="100%">
				            <Table variant='simple'  width="100%" borderWidth="1px" borderColor="#CCCCCC"    w="369px"  h="40px">
				              <Thead bg="#CCCCCC" borderStyle="solid">
				                <Tr style={{border:"1px solid #CCCCCC"}}>
				                  <Th style={{padding:"10px 30px",color:"black"}}>User Name</Th>
				                  <Th style={{padding:"10px 30px",color:"black"}}>Count</Th>                  
				                </Tr>
				              </Thead>

				              <Tbody fontSize="14px">

				              {data?.UserNoVoterCount.map((rs,index)=>{
	                    return(
	                           <Tr key={index} borderBottomWidth="2px" borderColor="#CCCCCC" >
	                            <Td style={{padding:"10px 30px"}}>{rs.name}</Td>
	                            <Td style={{padding:"10px 30px"}}>{rs.voter_count}</Td>
	                      
	                            </Tr>
	                          )
	                  })}
				             
				                              
				              </Tbody>
				             
				              
				            </Table>
				        </TableContainer>
					</Box>
					

					


				</HStack>	


				
				{/*Mobile Code*/}
				<Box mb="30px" className="d_desk_none">
						<Box  w="100%" mb="30px">
							<Text fontSize="14px" fontWeight="600" color="#000000" mb="10px">Top Voters Added</Text>
				        <TableContainer width="100%">
				            <Table variant='simple' className="mobile-table" width="100%" borderWidth="1px" borderColor="#CCCCCC" h="40px">
				              <Thead bg="#CCCCCC" borderStyle="solid">
				                <Tr style={{border:"1px solid #CCCCCC"}}>
				                  <Th style={{padding:"10px 30px",color:"black"}}>User Name</Th>
				                  <Th style={{padding:"10px 30px",color:"black"}}>Count</Th>                  
				                </Tr>
				              </Thead>

				              <Tbody fontSize="14px">

				              {data?.UserVoterCount.map((rs,index)=>{
				                    return(
				                           <Tr key={index} borderBottomWidth="2px" borderColor="#CCCCCC" >
				                            <Td style={{padding:"10px 30px"}}>{rs.name}</Td>
				                            <Td style={{padding:"10px 30px"}}>{rs.voter_count}</Td>
				                      
				                            </Tr>
				                          )
				                  })}    
				              </Tbody>
				             
				              
				            </Table>
				        </TableContainer>
						</Box>
					

						<Box w="100%">
							<Text fontSize="14px" fontWeight="600" color="#000000" mb="10px">No Voter Added</Text>
					        <TableContainer width="100%">
					            <Table variant='simple' className="mobile-table" width="100%" borderWidth="1px" borderColor="#CCCCCC" h="40px">
					              <Thead bg="#CCCCCC" borderStyle="solid">
					                <Tr style={{border:"1px solid #CCCCCC"}}>
					                  <Th style={{padding:"10px 30px",color:"black"}}>User Name</Th>
					                  <Th style={{padding:"10px 30px",color:"black"}}>Count</Th>                  
					                </Tr>
					              </Thead>

					              <Tbody fontSize="14px">

					              {data?.UserNoVoterCount.map((rs,index)=>{
		                    return(
		                           <Tr key={index} borderBottomWidth="2px" borderColor="#CCCCCC" >
		                            <Td style={{padding:"10px 30px"}}>{rs.name}</Td>
		                            <Td style={{padding:"10px 30px"}}>{rs.voter_count}</Td>
		                      
		                            </Tr>
		                          )
		                  })}
					             
					                              
					              </Tbody>
					             
					              
					            </Table>
					        </TableContainer>
						</Box>


				</Box>


			</div>
		</>
	)
}

export default Dashboard;