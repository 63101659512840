import React,{useEffect,useState} from 'react';
import { Button,useDisclosure,Text,Box ,HStack,Spinner} from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form} from '../../components/form'
import { fetchWrapper } from '../../helpers';
import { toast } from "react-toastify";



function Createdist({ loadList,meCloseEdit}){
	const [loading,setLoading] = useState(false)
	const [formData, setFormData] = useState({
    name: '',
    
  });

  	const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),

	});

	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       setLoading(true)
	     const url = `${process.env.REACT_APP_API_URL}/api/district-create`;
  		 await fetchWrapper.post(url,data)
  		 
  		 meCloseEdit();
  		 loadList();
  		 setLoading(false);
  		 toast(`Record has been saved `, {
	        type: "info"
	      });
	}

	return (

			<>
			{loading &&
	          <HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
	            <Spinner
	              thickness='4px'
	              speed='0.65s'
	              emptyColor='gray.200'
	              color='blue.500'
	              size='xl'
	            />
	          </HStack>
	        }
			<Text mb="20px" mt="-35px" fontWeight="900" fontSize="24px" fontFamily="'Open Sans', sans-serif">Add District</Text>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			             onSubmit ={onSubmit}
			            
			 >


			 	<InputText name="name" title="District Name"  />

			 	<Box mt="17px">
			 		<Button py="10px" px="25px" color="#fff" bg="#2D7A06" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif" w="142px"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Add District
		          	</Button>
		          	<Button py="10px" px="25px" color="#001F3F"  fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
			            mb={4}
			            colorScheme='whiteAlpha'
			            onClick={meCloseEdit}
			            type='button'
	          			>
	           		 	Close
        			</Button>
			 	</Box>
			</Form>

			</>

	)
}

export default Createdist;